import defaultWidgets from "@sportnet/content/editor/defaultWidgets";
import MatchesListEditor from "@sportnet/content/editor/widgets/MatchesList";
import TeamMatchesListEditor from "@sportnet/content/editor/widgets/TeamMatchesList";
import MatchOverviewEditor from "@sportnet/content/editor/widgets/MatchOverview";
import MatchDetailEditor from "@sportnet/content/editor/widgets/MatchDetail";
import CompetitionDraw from "@sportnet/content/editor/widgets/CompetitionDraw";
import PlayerStats from "@sportnet/content/editor/widgets/PlayerStats";
import ResultsTable from "@sportnet/content/editor/widgets/ResultsTable";
import Squad from "@sportnet/content/editor/widgets/Squad";

export const CONTENT_STATIC_FILES_BASE_URI = "https://embed.sportnet.online";

export const embeddableScriptSrc =
  "https://embed.sportnet.online/dist/v1/index.bundle.js";

export const embeddableUrl = "https://embed.sportnet.online";

export const sampleData: options = {
  matchDetail: {
    description: "",
    data: {
      appSpace: "VsFZ",
      competitionGroupId: "5d5fe0d56dc95601bfc7e4ad",
      competitionId: "647ba0837b634444d1c5174e",
      matchId: "64bb8a78cfdfa5c2bb9115fb",
      ownerPpo: "futbalsfz.sk",
      partId: "6486d42f76d0d348cd097868",
      roundId: "64a2f70076d0d348cd142585",
      showSections: ["preview", "sportGround"],
      seasonId: "6477a9707b634444d11594a2",
    },
  },
  matchesList: {
    description: "",
    data: {
      appSpace: "VsFZ",
      ownerPpo: "futbalsfz.sk",
      competitionId: "62cbdfc83658c77a210e2e93",
      partId: "62cbdfc8f24822c684af8c17",
      type: "matchesList",
      seasonId: "628f579d71632936092b45a5",
      sportSector: "futbal",
    },
  },
  teamMatchesList: {
    description: "",
    data: {
      appSpace: "fk-poprad.futbalnet.sk",
      sport: "futbal",
      teamId: "6497f167eebe726b04fab062",
      type: "teamMatchesList",
      dateFrom: "2024-01-01",
      title: "FK Poprad dospelí 2023/2024",
    },
  },
  matchOverview: {
    description: "",
    data: {
      appSpace: "VsFZ",
      ownerPpo: "futbalsfz.sk",
      competitionId: "62cbdfc83658c77a210e2e93",
      partId: "62cbdfc8f24822c684af8c17",
      roundId: "62cbdfc8f24822c684af8c18",
      matchId: "62cbe0a23658c77a210edd59",
      type: "matchOverview",
      seasonId: "628f579d71632936092b45a5",
      sportSector: "futbal",
    },
  },
  playerStats: {
    description: "",
    data: {
      appSpace: "VsFZ",
      ownerPpo: "futbalsfz.sk",
      competitionId: "62cbdfc83658c77a210e2e93",
      partId: "62cbdfc8f24822c684af8c17",
      type: "playerStats",
      seasonId: "628f579d71632936092b45a5",
      stats: [
        {
          value: "goals",
          label: "Góly",
        },
        {
          value: "minutes",
          label: "Počet odohraných minút",
        },
        {
          value: "match_appearances",
          label: "Počet stretnutí",
        },
      ],
      sportSector: "futbal",
    },
  },
  competitionDraw: {
    description: "",
    data: {
      appSpace: "iihf",
      ownerPpo: "iihf",
      competitionId: "6419bdf3617d2eaa62337ad3",
      partId: "6419bdef331a7636f169ff47",
      type: "competitionDraw",
      seasonId: "6419bd88617d2eaa62332177",
      sportSector: "ice-hockey",
    },
  },
  resultsTable: {
    description: "",
    data: {
      appSpace: "VsFZ",
      ownerPpo: "futbalsfz.sk",
      competitionId: "62cbdfc83658c77a210e2e93",
      partId: "62cbdfc8f24822c684af8c17",
      type: "resultsTable",
      seasonId: "628f579d71632936092b45a5",
      sportSector: "futbal",
    },
  },
  squad: {
    description: "",
    data: {
      appSpace: "futbalsfz.sk",
      teamId: "5e847aec44a91b65b4894c92",
      type: "squad",
      competitionId: "5ea2d4420bab94105c6c52ce",
      partId: "5f0c3ad64eebfa26fb713bf4",
    },
  },
};

export const supportedWidgetTypesArray = {
  competitionDraw: CompetitionDraw as React.ElementType,
  matchDetail: MatchDetailEditor as React.ElementType,
  matchOverview: MatchOverviewEditor as React.ElementType,
  matchesList: MatchesListEditor as React.ElementType,
  playerStats: PlayerStats as React.ElementType,
  resultsTable: ResultsTable as React.ElementType,
  squad: Squad as React.ElementType,
  teamMatchesList: TeamMatchesListEditor as React.ElementType,
};

export type SupportedWidgetTypes = keyof typeof supportedWidgetTypesArray;

type WidgetsType = {
  elementType: React.ElementType<any>;
  data: any;
  label: string;
  type: SupportedWidgetTypes;
}[];

export const themes = [
  { value: "light", label: "Svetlá" },
  { value: "dark", label: "Tmavá" },
];

export const widgets = (defaultWidgets as WidgetsType)
  .filter((w) => Object.keys(supportedWidgetTypesArray).includes(w.type))
  .map((w) => {
    return {
      ...w,
      elementType: supportedWidgetTypesArray[w.type],
      data: sampleData[w.type].data,
      description: sampleData[w.type].description,
    };
  });

type options = { [key in SupportedWidgetTypes]: any };

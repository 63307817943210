import React from 'react';
import { useGlobalState } from '../providers/GlobalProvider';
import { SupportedWidgetTypes, widgets } from '../constants';
import __ from '../utilities/__';
import { styled } from 'styled-components';
import { NavLink } from 'react-router-dom';
import WidgetCard from '../containers/WidgetCard';
import { theme } from '../theme/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  gap: 2rem;
  align-items: center;
  width: 100%;
`;

const LinkButton = styled(NavLink)`
  text-decoration: none;
  color: white;
  background: ${theme.color.primary}; /* fallback for old browsers */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 1rem;
  transition: all 0.15s ease;

  :hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
`;

const Home: React.FC = () => {
  const { setTitle, setDescription } = useGlobalState();
  React.useEffect(() => {
    setTitle('Embedovateľné widgety');
    setDescription('Od SportNetu');
  }, [setTitle, setDescription]);

  const showWidgets: SupportedWidgetTypes[] = [
    'matchDetail',
    'matchOverview',
    'matchesList',
    'resultsTable',
  ];

  return (
    <Wrapper>
      {widgets
        .filter((w) => showWidgets.includes(w.type as SupportedWidgetTypes))
        .map((w) => {
          return { label: w.label, data: w.data, type: w.type };
        })
        .map(
          (
            widget: {
              label: string;
              data: any[];
              type: SupportedWidgetTypes;
            },
            i: number
          ) => (
            <WidgetCard
              key={i}
              label={widget.label}
              widgetData={widget.data}
              widgetType={widget.type}
            />
          )
        )}
      <LinkButton to={'/widgets'}>{__('Zobraziť všetky widgety')}</LinkButton>
    </Wrapper>
  );
};

export default Home;

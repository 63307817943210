import React from 'react';
import { useGlobalState } from '../providers/GlobalProvider';

const Terms: React.FC = () => {
  const { setTitle, setDescription } = useGlobalState();
  React.useEffect(() => {
    setTitle('Podmienky používania');
    setDescription('SportNet');
  }, [setTitle, setDescription]);
  return <p>Na tejto stránke pracujeme...</p>;
};

export default Terms;

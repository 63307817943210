import * as React from 'react';
import CmsApi from '@sportnet/content/api/CmsApi';
import Content from '@sportnet/content/view';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import __ from '../utilities/__';
import Loader from '@sportnet/ui/Loader';
import SportnetWrapper from './SportnetWrapper';
import { SupportedWidgetTypes } from '../constants';
import { theme } from '../theme/theme';
import LightMode from '../components/icons/LightMode';
import { mb } from '../utilities/mb';

const Wrapper = styled.div<{ dark?: boolean }>`
  position: relative;
  z-index: 0;
  display: flex;
  max-width: calc(100vw - ${rem(32)});
  flex-direction: column;
  align-items: center;
  margin: ${rem(16)};
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  text-decoration: none;
  transition: all ease 0.2s;
  overflow: auto;
  border-radius: ${rem(16)};
  color: ${theme.color.base};
  background-color: ${theme.color.background};

  ${({ dark }) =>
    !!dark &&
    css`
      background-color: ${theme.color.fadedText};
      color: ${theme.color.background};
    `}
`;

const SkeletonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: ${rem(256)};
`;

const SwitchButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: ${rem(8)};
  align-items: center;

  ${mb('s')} {
    width: auto;
    position: absolute;
    right: ${rem(20)};
    top: ${rem(20)};
  }
`;

const SwitchButton = styled.label`
  position: relative;
  display: inline-block;
  width: ${rem(32)};
  height: ${rem(16)};

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: ${rem(32)};
    box-shadow: rgba(99, 99, 99, 0.6) 0px 2px 4px 0px;
    box-sizing: border-box;
  }

  span:before {
    position: absolute;
    content: '';
    height: ${rem(10)};
    width: ${rem(10)};
    left: ${rem(3)};
    bottom: ${rem(3)};
    background-color: ${theme.color.fadedText};
    -webkit-transition: 0.4s;

    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: ${theme.color.background};
  }

  input:checked + span:before {
    -webkit-transform: translateX(${rem(16)});
    -ms-transform: translateX(${rem(16)});
    transform: translateX(${rem(16)});
  }
`;

const WidgetCard: React.FC<{
  widgetData: any;
  widgetType: SupportedWidgetTypes;
  label: string;
}> = ({ widgetData, widgetType, label }) => {
  const [expandedWidget, setExpandedWidget] = React.useState<any>();
  const [dark, setDark] = React.useState(false);

  React.useState(() => {
    const expandWidgets = async (
      widgetType: SupportedWidgetTypes,
      widgetData: any
    ) => {
      try {
        const response = await CmsApi.expandWidgets(
          'pages',
          widgetData.appSpace,
          'default',
          {},
          {
            content: [{ ...widgetData, type: widgetType }],
          }
        );
        setExpandedWidget(response.content || []);
      } catch (e) {
        setExpandedWidget(undefined);
        console.error(e);
      }
    };
    expandWidgets(widgetType, widgetData);
  });

  return (
    <Wrapper dark={dark}>
      <h3>{__(label)}</h3>
      <SwitchButtonWrapper>
        <LightMode dark={dark} />
        <SwitchButton>
          <input type="checkbox" onChange={() => setDark(!dark)} />
          <span></span>
        </SwitchButton>
      </SwitchButtonWrapper>
      {expandedWidget ? (
        <SportnetWrapper data={expandedWidget} dark={dark}>
          <Content items={expandedWidget} />
        </SportnetWrapper>
      ) : (
        <SkeletonWrapper>
          <Loader size="xxl" color={theme.color.primary} />
        </SkeletonWrapper>
      )}
    </Wrapper>
  );
};

export default WidgetCard;

import * as React from 'react';
import styled from 'styled-components';
import WidgetDocumentation from '../containers/WidgetDocumentation';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import { widgets } from '../constants';
import { useGlobalState } from '../providers/GlobalProvider';
import rem from '../utilities/rem';

const Wrapper = styled.div``;

const StyledSegmentHeader = styled(SegmentHeader)`
  padding: ${rem(16)};
  padding-bottom: 0;
`;

const StyledSegment = styled(Segment)`
  padding: ${rem(0)};
`;

type Props = {};

const Widgets: React.FC<Props> = () => {
  const { setTitle, setDescription } = useGlobalState();
  React.useEffect(() => {
    setTitle('Zoznam Widgetov');
    setDescription('SportNet');
  }, [setTitle, setDescription]);

  const key = window.location.hash?.substring(1);
  React.useEffect(() => {
    if (key) {
      const doc = document.getElementById(key);
      if (doc) {
        setTimeout(() => {
          doc.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }, 300);
      }
    }
  });

  return (
    <Wrapper>
      {widgets?.map((w: any, idx: number) => {
        return (
          <div id={w.type} key={idx}>
            <StyledSegment
              key={w.type}
              raised
              header={
                <StyledSegmentHeader
                  isCollapsed={w.type !== key}
                  withSeparator
                  collapsible
                >
                  {w.label}
                </StyledSegmentHeader>
              }
            >
              <WidgetDocumentation
                description={w.description}
                widgetData={w.data}
                widgetType={w.type}
              />
            </StyledSegment>
          </div>
        );
      })}
    </Wrapper>
  );
};

export default Widgets;

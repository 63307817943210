import { Context, createContext, useState } from 'react';
import * as React from 'react';

type TitleContextType = {
  title?: string;
  setTitle: (title?: string) => void;
  description?: string;
  setDescription: (description?: string) => void;
};

const GlobalContext: Context<TitleContextType> =
  createContext<TitleContextType>({
    setTitle: () => {},
    setDescription: () => {},
  });

type Props = { children: JSX.Element };

const GlobalProvider: React.FC<Props> = ({ children }) => {
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();

  const exposed = {
    title,
    setTitle,
    description,
    setDescription,
  };

  return (
    <GlobalContext.Provider value={exposed}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalState = () => React.useContext(GlobalContext);

export default GlobalProvider;

const queryStringToObject = (queryString: string) => {
  const params = new URLSearchParams(queryString);
  const obj: any = {};
  for (const [key, value] of params.entries()) {
    const keys = key.split('[');
    let currentObj = obj;

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i].replace(']', '');

      if (i === keys.length - 1) {
        // Handle comma-separated values as arrays
        if (value.includes(',')) {
          const valueArray = value.split(',');
          currentObj[k] = valueArray.map((v: any) => decodeURIComponent(v));
        } else {
          // Check if it's an array index
          if (!isNaN(parseInt(k))) {
            // Ensure that currentObj is an array
            currentObj = currentObj || [];
            currentObj[parseInt(k)] = decodeURIComponent(value);
          } else {
            // It's not an array index, treat it as a regular property
            currentObj[k] = decodeURIComponent(value);
          }
        }
      } else {
        // If it's not the last key, create nested objects or arrays
        currentObj[k] =
          currentObj[k] || (isNaN(parseInt(keys[i + 1])) ? {} : []);
        currentObj = currentObj[k];
      }
    }
  }
  return obj;
};

export default queryStringToObject;

import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';
import __ from '../utilities/__';
import { Link } from 'react-router-dom';
import { mediaSizes } from '../utilities/mb';
import { embeddableUrl } from '../constants';
import SportnetOnlineLogo from '../components/SportnetOnlineLogo';
import SportnetLogo from '../components/SportnetLogo';
import { fluidFontSize, theme } from '../theme/theme';
import objectToQueryString from '../utilities/objectToQueryString';

const Wrapper = styled.div<{ dark?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${rem(4)};
  justify-content: center;
  padding: ${rem(16)};

  ${({ dark }) =>
    !!dark &&
    css`
      * :not([style]) {
        color: ${theme.color.background} !important;
      }

      // matchesList
      * .content-widget-matchesList > div > div > div {
        > div,
        > div > div {
          background-color: ${theme.color.fadedText} !important;
        }
        > div:nth-child(2n + 1) {
          background-color: ${theme.color.fadedTextAccent} !important;
        }

        svg > path {
          fill: rgba(255, 255, 255, 0.5) !important;
        }
      }

      // resultsTable
      * > table > tbody > tr:hover > td {
        background-color: ${theme.color.fadedTextAccent} !important;
      }
    `}
`;

const ChildrenWrapper = styled.div``;

const Footer = styled.div`
  padding-top: ${rem(16)};
  display: flex;
  width: 100%;
  border-top: ${rem(1)} solid #e8e8e8;
  justify-content: space-between;
  align-items: center;
  gap: ${rem(4)};
  font-family: ${theme.fontFamily} !important;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(4)};
  justify-content: center;
`;

const StyledLink = styled.div`
  color: ${theme.color.fadedText};
  transition: all ease 0.2s;
  ${fluidFontSize('smallText', mediaSizes.l, 1920)};
  font-family: ${theme.fontFamily};
`;

const RouterLink = styled(Link)`
  color: ${theme.color.fadedText};
  transition: all ease 0.2s;
  ${fluidFontSize('smallText', mediaSizes.l, 1920)};
  text-decoration: none;
  font-family: ${theme.fontFamily};
`;

const FooterText = styled.span`
  color: ${theme.color.fadedText};
  transition: all ease 0.2s;
  ${fluidFontSize('smallText', mediaSizes.l, 1920)};
  text-decoration: none;

  :hover {
    cursor: pointer;
    opacity: 0.7;
    color: red;
  }
  font-family: ${theme.fontFamily};
`;

type Props = {
  children: React.ReactElement;
  data: any;
  dark?: boolean;
};

const SportnetWrapper: React.FC<Props> = ({ children, data, dark }) => {
  // const urlParam: any = [];

  const searchParams = React.useMemo(() => {
    const params = Array.isArray(data)
      ? { ...data[0], widgetTheme: dark ? 'dark' : 'light' }
      : { ...data, widgetTheme: dark ? 'dark' : 'light' };
    delete params.data;
    return params;
  }, [data, dark]);

  const urlParam = React.useMemo(() => {
    return objectToQueryString(searchParams);
  }, [searchParams]);

  const findFlexElements: any = React.useCallback((parentElement: any) => {
    var flexElements = [];
    var children = parentElement.children;

    for (var i = 0; i < children.length; i++) {
      var child = children[i];

      // Check if the current child has display: flex
      var computedStyle = getComputedStyle(child);
      if (computedStyle.getPropertyValue('display') === 'flex') {
        flexElements.push(child);
      }

      // Recursively search for flex elements within this child
      flexElements = flexElements.concat(findFlexElements(child));
    }

    return flexElements as any;
  }, []);

  React.useEffect(() => {
    if (dark) {
      var titleElements = document.querySelectorAll('title');
      var golTitleElements = Array.from(titleElements).filter(function (
        titleElement
      ) {
        return (
          titleElement.textContent?.toLowerCase().includes('gól') ||
          titleElement.textContent?.toLowerCase().includes('kop')
        );
      });
      golTitleElements.forEach(function (titleElement) {
        // Navigate to the parent <svg> element
        var svgElement = titleElement.closest('svg');

        // Update the fill attribute of <path> elements within this <svg>
        var pathElements = svgElement?.querySelectorAll('path');
        pathElements?.forEach(function (pathElement) {
          pathElement.setAttribute('fill', '#fff');
        });
      });

      var widgetMatchDetailElements = document.querySelectorAll(
        '.content-widget-matchDetail'
      );
      widgetMatchDetailElements.forEach(function (widgetMatchDetailElement) {
        var flexElements = findFlexElements(widgetMatchDetailElement);

        // Now you can work with the flexElements array, which contains all flex elements
        flexElements
          .filter(
            (e: any) =>
              e?.children?.length === 3 &&
              e.children[1]?.children &&
              [...e.children[1].children].length === 2
          )
          .forEach(function (flexElement: any) {
            const [, timeWrapper] = flexElement.children;
            const [, time] = [...timeWrapper.children];
            // Change the background color to #000 or apply any other styles as needed
            if (/\d+'/.test(time.innerText)) {
              timeWrapper.style.backgroundColor = theme.color.fadedText;
              timeWrapper.style.color = '#fff';
              time.style.backgroundColor = theme.color.fadedText;
            }
          });
      });
    }
  }, [dark, findFlexElements]);

  const handleNavigate = (path: string, params?: any) => {
    if (!!params) {
      window.open(`${embeddableUrl}/${path}?${params}`);
    } else {
      window.open(`${embeddableUrl}/${path}`);
    }
  };

  return (
    <Wrapper dark={dark}>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <Footer>
        <FlexWrapper>
          <FooterText>
            {process.env.NODE_ENV === 'production' &&
            embeddableUrl !== origin ? (
              <StyledLink
                className="link"
                onClick={() => handleNavigate('editor', urlParam)}
              >
                {__('Použiť widget')}
              </StyledLink>
            ) : (
              <RouterLink to={`/editor?${urlParam}`}>
                {__('Použiť widget')}
              </RouterLink>
            )}
          </FooterText>
          <StyledLink>|</StyledLink>
          <FooterText>
            {process.env.NODE_ENV === 'production' &&
            embeddableUrl !== origin ? (
              <StyledLink
                className="link"
                onClick={() => handleNavigate('terms')}
              >
                {__('Podmienky používania')}
              </StyledLink>
            ) : (
              <RouterLink to={`/terms`}>
                {__('Podmienky používania')}
              </RouterLink>
            )}
          </FooterText>
        </FlexWrapper>
        <FlexWrapper style={{ gap: rem(8) }}>
          <StyledLink
            onClick={() => {
              window.open('https://sportnet.sme.sk');
            }}
            style={{ cursor: 'pointer' }}
          >
            <SportnetLogo dark={dark} />
          </StyledLink>
          <StyledLink>|</StyledLink>
          <FlexWrapper
            onClick={() => {
              window.open('https://sportnet.online');
            }}
            style={{ cursor: 'pointer' }}
          >
            <SportnetOnlineLogo dark={dark} />
          </FlexWrapper>
        </FlexWrapper>
      </Footer>
    </Wrapper>
  );
};

export default SportnetWrapper;

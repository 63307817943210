import React from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../providers/GlobalProvider';
import { Link } from 'react-router-dom';
import { mediaSizes } from '../utilities/mb';
import { fluidFontSize, theme } from '../theme/theme';

const Wrapper = styled.div`
  width: 100%;
  margin: 0;
  background-color: ${theme.color.background};
`;

const Navigation = styled.div`
  width: 100%;
  position: absolute;
  z-index: 100;
  top: 0;
  padding: 0 2rem;
  box-shadow: none;
  background: transparent;

  > div {
    max-width: 75rem;
    display: flex;
    flex-direction: row;
    padding: 1.75rem 0;
    margin: 0 auto;
    color: ${theme.color.background};
    align-items: center;
    justify-content: space-between;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RouterLink = styled(Link)`
  margin-left: 0;
  color: ${theme.color.background};
  text-decoration: none;
  display: flex;
  ${fluidFontSize('text', mediaSizes.l, 1920)};
`;

const Logo = styled.img``;

const Links = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Header = styled.div`
  display: flex;
  text-align: left;
  background-image: url('/background.png');
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  justify-content: center;
  text-transform: uppercase;
  flex-direction: column;

  > div {
    margin: 8.75rem auto 0 auto;
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 1rem;
    flex-direction: column;
  }
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  width: 100%;
  align-items: center;

  > div {
    max-width: 75rem;
  }
`;

const Title = styled.h1`
  text-align: center;
  color: ${theme.color.background};
  margin-bottom: 1.75rem;
  ${fluidFontSize('title', mediaSizes.l, 1920)};
  text-transform: uppercase;
  font-weight: 900;
  font-family: Montserrat, sans-serif;
`;

const Description = styled.h2`
  text-align: center;
  color: ${theme.color.background};
  margin-bottom: 1.75rem;
  ${fluidFontSize('subtitle', mediaSizes.l, 1920)};
`;

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const { title, description } = useGlobalState();
  return (
    <Wrapper>
      <Navigation>
        <div>
          <LogoWrapper>
            <RouterLink to="/">
              <Logo
                src={'./sportnet-online-dark.svg'}
                alt="sportnet"
                width={160}
                height={48}
                style={{ objectFit: 'contain' }}
              />
            </RouterLink>
          </LogoWrapper>
          <Links>
            <RouterLink to="/widgets">Zoznam Widgetov</RouterLink>
          </Links>
        </div>
      </Navigation>
      <Header>
        <div>
          <Title>{title}</Title>
          {!!description && <Description>{description}</Description>}
        </div>
        <img
          width={0}
          height={0}
          sizes="100vw"
          style={{ width: '100%', height: 'auto' }}
          src={'./frame.png'}
          alt="Vaše digitálne služby"
        />
      </Header>

      <ChildrenWrapper>
        <div style={{ width: '100%' }}>{children}</div>
      </ChildrenWrapper>
    </Wrapper>
  );
};

export default Layout;

import baseTheme, { Theme } from './baseTheme';
import fluidTypography from '../utilities/fluidTypography';

const fontSize = {
  text: {
    min: 13,
    max: 15,
  },
  smallText: {
    min: 11,
    max: 13,
  },
  title: {
    min: 28,
    max: 36,
  },
  subtitle: {
    min: 18,
    max: 20,
  },
};

export const fluidFontSize = (
  type: keyof typeof fontSize,
  from: number,
  to: number
) => fluidTypography(fontSize[type].min, fontSize[type].max, from, to);

export const theme = {
  ...baseTheme,
};

export interface ThemeInterface extends Theme {}

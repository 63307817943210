type Color = string;
type FontFamily = string;

export interface Theme {
  color: {
    base: Color;
    background: Color;
    primary: Color;
    fadedText: Color;
    fadedTextAccent: Color;
  };

  fontFamily: FontFamily;
}

type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> };

export const getTheme = (override: RecursivePartial<Theme> = {}): Theme => {
  const color = {
    base: '#000',
    background: '#fff',
    primary: '#F92539',
    fadedText: '#2d2d2d',
    fadedTextAccent: '#3a3a3a',
  };

  const theme = {
    fontFamily: override.fontFamily || "'Roboto', sans-serif",
  };

  return {
    ...theme,
    color,
  };
};

export default getTheme();

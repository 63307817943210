const objectToQueryString: any = (
  searchParams: any,
  parentKey: string | null = null
) => {
  const parts = [];

  for (const key in searchParams) {
    if (Object.prototype.hasOwnProperty.call(searchParams, key)) {
      const value = searchParams[key];
      const currentKey = parentKey ? `${parentKey}[${key}]` : key;

      if (typeof value === 'object' && !Array.isArray(value)) {
        parts.push(objectToQueryString(value, currentKey));
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (
            typeof item === 'object' &&
            !Array.isArray(item) &&
            item !== null
          ) {
            parts.push(objectToQueryString(item, `${currentKey}[${index}]`));
          } else {
            parts.push(
              `${encodeURIComponent(currentKey)}=${encodeURIComponent(
                value.join(',')
              )}`
            );
          }
        });
      } else {
        parts.push(
          `${encodeURIComponent(currentKey)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return parts.join('&');
};

export default objectToQueryString;

import React from 'react';
import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';
import Editor from './pages/Editor';
import Terms from './pages/Terms';
import Widgets from './pages/Widgets';
import Layout from './containers/Layout';
import Home from './pages/Home';
import GlobalProvider from './providers/GlobalProvider';
import { ThemeProvider } from 'styled-components';
import { theme as defaultContentTheme } from '@sportnet/content/theme/theme';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={defaultContentTheme}>
      <GlobalProvider>
        <BrowserRouter>
          <Routes>
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route path="/" element={<Home />} />
              <Route path="/editor" element={<Editor />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/widgets" element={<Widgets />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </GlobalProvider>
    </ThemeProvider>
  );
};
export default App;

import FormField from '@sportnet/ui/FormField';
import * as React from 'react';
import __ from '../utilities/__';
import styled, { css } from 'styled-components';
import CmsApi from '@sportnet/content/api/CmsApi';
import Content from '@sportnet/content/view';
import setupApis from '@sportnet/content/api/setupApis';
import Col, { Row } from '@sportnet/ui/Grid';
import Loader from '@sportnet/ui/Loader';
import { rem } from 'polished';
import Button from '@sportnet/ui/Button';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Input from '@sportnet/ui/Input';
import Tooltip from '@sportnet/ui/Tooltip';
import Icon from '@sportnet/ui/Icon';
import {
  SupportedWidgetTypes,
  embeddableScriptSrc,
  widgets,
  themes,
  CONTENT_STATIC_FILES_BASE_URI,
} from '../constants';
import { useGlobalState } from '../providers/GlobalProvider';
import { mediaSizes } from '../utilities/mb';
import { fluidFontSize, theme } from '../theme/theme';
import SportnetWrapper from '../containers/SportnetWrapper';
import queryStringToObject from '../utilities/queryStringToObject';
import ContentEditorContext, {
  IAppContext,
} from '@sportnet/content/editor/appContext';
import CONTENT_CONFIG from '@sportnet/content/utilities/config';
import ContentContext from '@sportnet/content/common/ContentContext';

setupApis();

type ChildrenProp = {
  children: React.ReactNode;
};

const Wrapper = styled.div`
  width: 100%;
`;

const WidgetWrapper = styled.div<{ dark?: boolean }>`
  padding: ${rem(24)};
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0 ${rem(3)} ${rem(8)};

  ${({ dark }) =>
    !!dark &&
    css`
      background-color: ${theme.color.fadedText};
      color: ${theme.color.background};
    `}
`;

const StyledModalContent = styled(ModalContent)`
  /* padding: ${rem(8)}; */
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
`;

const PreviewWrapper = styled.div`
  width: '100%';
`;

const Title = styled.div`
  ${fluidFontSize('title', mediaSizes.l, 1920)};
`;

const StyledCol = styled(Col)<ChildrenProp>``;
const StyledModal = styled(Modal)<ChildrenProp>``;
const StyledTooltip = styled(Tooltip)<ChildrenProp>``;

type SelectType = {
  value: SupportedWidgetTypes;
  label: string;
  elementType: React.ElementType;
};

export interface IWidget {
  type?: string;
  [key: string]: any;
}

const Editor: React.FC<{}> = () => {
  const { setTitle, setDescription } = useGlobalState();

  React.useEffect(() => {
    setTitle('Editor Widgetov');
    setDescription('SportNet');
  }, [setTitle, setDescription]);

  const initData = {};

  const expandWidget = async (d: {
    appSpace: string;
    [key: string]: unknown;
  }) => {
    if (!d.appSpace) {
      return;
    }
    try {
      setIsExpanding(true);
      const response = await CmsApi.expandWidgets(
        'pages',
        d.appSpace,
        'default',
        {},
        {
          content: [{ ...d, type: selectedWidget?.value }],
        }
      );
      setExpandedWidgets(response.content || []);
    } catch (e) {
      setExpandedWidgets([]);
      console.error(e);
    } finally {
      setIsExpanding(false);
    }
  };

  const handleOnChangeWidget = (d: any) => {
    setData(d);
  };

  const [expandedWidgets, setExpandedWidgets] = React.useState<IWidget[]>([]);
  const [isExpanding, setIsExpanding] = React.useState(false);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [data, setData] = React.useState<any>({});
  const [apiKey, setApiKey] = React.useState('');
  const [selectedTheme, setSelectedTheme] = React.useState(themes[0]);
  const [selectedWidget, setSelectedWidget] = React.useState<
    SelectType | undefined
  >();

  React.useEffect(() => {
    const obj = queryStringToObject(window.location.search);
    setData(obj);
    setSelectedTheme(
      themes.find((t) => t.value === obj.widgetTheme) || themes[0]
    );
    setSelectedWidget(
      widgets
        .map((w) => ({
          value: w.type,
          label: w.label,
          elementType: w.elementType,
        }))
        .find((w) => w.value === obj.type)
    );
  }, []);

  const handleSelectWidget = (selectData: SelectType) => {
    setExpandedWidgets([]);
    setData(initData);
    setSelectedWidget(selectData);
  };

  const handleGetWidget = (e: any) => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const getDivFromData = () => {
    const wrapper = document.createElement('div');
    const div = document.createElement('div');
    if (!data || !div || !selectedWidget) return '';
    div.className = selectedWidget.value;
    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'string') {
        div.dataset[key] = data[key];
        if (!div.dataset[key] || div.dataset[key] === 'undefined') {
          delete div.dataset[key];
        }
      } else {
        div.dataset[key] = JSON.stringify(data[key]);
        if (!div.dataset[key] || div.dataset[key] === 'undefined') {
          delete div.dataset[key];
        }
      }
    });

    div.dataset['widgetTheme'] = selectedTheme.value;
    wrapper.appendChild(div);
    return wrapper.innerHTML;
  };

  React.useEffect(() => {
    expandWidget(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const context: IAppContext = React.useMemo(() => {
    return {
      auth: {
        appId: '',
        appSpace: '',
        token: '',
        contentDivider: CONTENT_CONFIG.DEFAULT_CONTENT_DIVIDER,
      },
      variables: {},
      availableWidgets: [],
      sectionTree: [],
      doctypes: [],
      staticContents: [],
      calendars: [],
      theme: {},
      articlelistDisplayOptions: [],
      environment: 'embed',
    };
  }, []);

  return (
    <Wrapper>
      <FormField
        label={__('Typ Widgetu')}
        type="theselect"
        value={selectedWidget}
        onChange={handleSelectWidget}
        options={widgets?.map((w) => ({
          value: w.type,
          label: w.label,
          elementType: w.elementType,
        }))}
      />
      <FormField
        label={__('Téma')}
        type="theselect"
        value={selectedTheme}
        onChange={setSelectedTheme}
        options={themes}
      />
      <Row style={{ width: '100%' }}>
        <StyledCol m={12} l={4}>
          {selectedWidget && (
            <ContentEditorContext.Provider value={context}>
              <selectedWidget.elementType
                onChange={(e: any) => {
                  handleOnChangeWidget(e);
                }}
                data={data}
              />
            </ContentEditorContext.Provider>
          )}
        </StyledCol>
        <StyledCol m={12} l={8}>
          {selectedWidget && (
            <WidgetWrapper dark={selectedTheme.value === 'dark'}>
              <Title>Ukážka</Title>
              {isExpanding && <Loader size="xl" />}
              {!isExpanding &&
                expandedWidgets &&
                expandedWidgets.length > 0 && (
                  <>
                    <PreviewWrapper>
                      <SportnetWrapper
                        data={data}
                        dark={selectedTheme.value === 'dark'}
                      >
                        <ContentContext.Provider
                          value={{
                            staticFilesBaseUrl: CONTENT_STATIC_FILES_BASE_URI,
                          }}
                        >
                          <Content items={expandedWidgets as any} />
                        </ContentContext.Provider>
                      </SportnetWrapper>
                    </PreviewWrapper>

                    <Button primary onClick={handleGetWidget}>
                      {__('Získať Widget')}
                    </Button>
                    <StyledModal
                      isOpen={modalIsOpen}
                      handleClose={handleCloseModal}
                    >
                      <StyledModalContent>
                        <div>
                          <div
                            style={{
                              marginBottom: rem(8),
                              display: 'flex',
                              gap: rem(4),
                              alignItems: 'center',
                            }}
                          >
                            <StyledTooltip
                              content={__(
                                'Pre získanie API kľuča nás kontaktujte na https://sportnet.online/informacie/kontakt'
                              )}
                            >
                              <Icon size={'s'} name="tooltip" />
                            </StyledTooltip>
                            <span style={{ color: 'red' }}>{'* '}</span>
                            {__('Api kľúč:')}
                          </div>

                          <Input
                            id={'apiKey-input'}
                            value={apiKey || ''}
                            onChange={(e) => {
                              setApiKey(e.target.value || '');
                            }}
                          >
                            <input />
                          </Input>
                        </div>

                        <div>
                          <div style={{ marginBottom: rem(8) }}>
                            {__('Script potrebný v <head>:')}
                          </div>
                          <Input
                            id={'header-input'}
                            disabled
                            value={`<script src="${embeddableScriptSrc}" apiKey="${apiKey}"></script>`}
                          >
                            <Input.Button
                              id="header-button"
                              onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                              ) => {
                                const input = document.getElementById(
                                  'header-input'
                                ) as HTMLInputElement;
                                const button = document.getElementById(
                                  'header-button'
                                ) as HTMLButtonElement;
                                if (input?.value && button?.innerHTML) {
                                  navigator.clipboard.writeText(input.value);

                                  const innerHtml = button.innerHTML;
                                  button.innerHTML = 'copied';
                                  setTimeout(() => {
                                    button.innerHTML = innerHtml;
                                  }, 2000);
                                }
                              }}
                              primary
                              basic
                              icon="files"
                            />
                            <input />
                          </Input>
                        </div>

                        <div>
                          <div style={{ marginBottom: rem(8) }}>
                            {__('Implementácia do <body>:')}
                          </div>
                          <Input
                            id="body-input"
                            disabled
                            value={getDivFromData()}
                          >
                            <Input.Button
                              id="body-button"
                              onClick={() => {
                                const input = document.getElementById(
                                  'body-input'
                                ) as HTMLInputElement;
                                const button = document.getElementById(
                                  'body-button'
                                ) as HTMLButtonElement;
                                if (input?.value && button?.innerHTML) {
                                  navigator.clipboard.writeText(input.value);

                                  const innerHtml = button.innerHTML;
                                  button.innerHTML = 'copied';
                                  setTimeout(() => {
                                    button.innerHTML = innerHtml;
                                  }, 2000);
                                }
                              }}
                              primary
                              basic
                              icon="files"
                            />
                            <input />
                          </Input>
                        </div>
                      </StyledModalContent>
                      <ModalActions>
                        <Button primary basic onClick={handleCloseModal}>
                          {__('Zavrieť')}
                        </Button>
                      </ModalActions>
                    </StyledModal>
                  </>
                )}
              {!isExpanding && expandedWidgets.length === 0 && (
                <>{__('Na tejto ploche bude vykreslený widget')}</>
              )}
            </WidgetWrapper>
          )}
        </StyledCol>
      </Row>
    </Wrapper>
  );
};

export default Editor;

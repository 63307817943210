import Col, { Row } from '@sportnet/ui/Grid';
import * as React from 'react';
import styled from 'styled-components';
import CmsApi from '@sportnet/content/api/CmsApi';
import Content from '@sportnet/content/view';
import SportnetWrapper from './SportnetWrapper';
import { SupportedWidgetTypes } from '../constants';

type ChildrenProp = {
  children: React.ReactNode;
};

const Wrapper = styled.div``;

const StyledCol = styled(Col)<ChildrenProp>``;

type Props = {
  description: string;
  widgetData: any;
  widgetType: SupportedWidgetTypes;
};

const WidgetDocumentation: React.FC<Props> = ({
  description,
  widgetData,
  widgetType,
}) => {
  const [expandedWidget, setExpandedWidget] = React.useState<any>();

  React.useState(() => {
    const expandWidgets = async (
      widgetType: SupportedWidgetTypes,
      widgetData: any
    ) => {
      try {
        const response = await CmsApi.expandWidgets(
          'pages',
          widgetData.appSpace,
          'default',
          {},
          {
            content: [{ ...widgetData, type: widgetType }],
          }
        );
        setExpandedWidget(response.content || []);
      } catch (e) {
        setExpandedWidget(undefined);
        console.error(e);
      }
    };
    expandWidgets(widgetType, widgetData);
  });

  return (
    <Wrapper>
      <Row>
        <StyledCol>{description}</StyledCol>
        <StyledCol>
          {expandedWidget && (
            <SportnetWrapper data={expandedWidget}>
              <Content items={expandedWidget} />
            </SportnetWrapper>
          )}
        </StyledCol>
      </Row>
    </Wrapper>
  );
};

export default WidgetDocumentation;
